import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import PostCard from "../components/postcard"

const OtherPosts = ({currentPostSlug}) => {
	const data = useStaticQuery(graphql`
		{
			allMarkdownRemark(sort: {order: DESC, fields: frontmatter___date}, limit: 3) {
				nodes {
					frontmatter {
						author
						date(formatString: "DD MMMM, YYYY")
						description
						img_url
						slug
						title
					}
				}
			}
		}
	`)
	const data1 = data.allMarkdownRemark.nodes.filter(post => post.frontmatter.slug !== currentPostSlug)
	const data2 = data1.slice(0, 2)
	return (
		<>
			<div className="other-posts">
				{
					data2.map((post, index) => {
						if (post.frontmatter.slug !== currentPostSlug) {
							return (
								<div className="col-md-6 col-sm-12" key={index.toString()}>
									<PostCard
										postSlug={post.frontmatter.slug}
										postThumb={post.frontmatter.img_url}
										postTitle={post.frontmatter.title}
										postDesc={post.frontmatter.description}
										postAuthor={post.frontmatter.author}
										postDate={post.frontmatter.date}
									/>
								</div>
							)
						} else {
							return (<></>)
						}
					})
				}
			</div>
		</>
	)	
};

export default OtherPosts;
