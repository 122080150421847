import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ShareButtons from "../components/sharebuttons"
import Subscribe from "../components/subscribe"
import OtherPosts from "../components/otherposts"

import "../components/css/style.css"
import "../components/css/post.css"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <>
      <Layout>
        <SEO title={frontmatter.title}/>
        <div className="content">
          <div className="container">
            <div className="row">
              <div className="wrapper">
                <div className="blog-post">
                  <h1 className="post-title">{frontmatter.title}</h1>
                  <h5 className="post-author">{frontmatter.author} 	&#9679; {frontmatter.date}</h5>

                  <div className="post-image">
                    <img src={frontmatter.img_url} draggable="false" alt={frontmatter.title} />
                  </div>

                  <div
                    className="blog-post-content"
                    dangerouslySetInnerHTML={{ __html: html }}
                  />
                  <hr />
                  <div className="blog-post-share">
                    <p>
                      Share this article&nbsp;
                      <ShareButtons title={frontmatter.title} iconSize={32} twitterHandle="furrble_in" url={typeof window !== "undefined" ? window.location.href : ''} />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="wrapper">
                <Subscribe />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="other-posts-title col-12">
                <h1 className="title">Other <span className="t-primary">articles</span></h1>
              </div>
              <OtherPosts currentPostSlug={frontmatter.slug} />
              <div className="all-posts-btn col-12">
                <Link to="/posts">
                  <button className="btn">View All Articles &gt;&gt;</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        author
        date(formatString: "DD MMMM, YYYY")
        title
        img_url
      }
    }
  }
`
