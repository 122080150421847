import React  from "react";

import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    LinkedinShareButton,
    LinkedinIcon,
    WhatsappShareButton,
    WhatsappIcon,
 } from "react-share";

 const ShareButtons = ({title, iconSize, twitterHandle, url}) => {
    return(
        <span className="share-buttons">
          <FacebookShareButton url={url} >
                <FacebookIcon size={iconSize} />
         </FacebookShareButton>

          <TwitterShareButton url={url} title={title} via={twitterHandle}>
                <TwitterIcon size={iconSize} />
          </TwitterShareButton>

          <LinkedinShareButton url={url} >
            <LinkedinIcon size={iconSize} />
          </LinkedinShareButton>

          <WhatsappShareButton url={url} title={title}>
               <WhatsappIcon size={iconSize} />
           </WhatsappShareButton>
        </span>
      )

}
export default ShareButtons