import React from "react"

import "../components/css/subscribe.css"

const Subscribe = () => (
	<div id="subscribe" className="subscribe">
		<div className="newsletter">
			<h2 className="section-head">Want to stay updated?</h2>
			<p>Subscribe to our newsletter now!</p>
			<div id="mc_embed_signup">
				<form className="subscribe-form" action="https://furrble.us7.list-manage.com/subscribe/post?u=7f9dc28cc5061e5b17f436c03&amp;id=39a76ccf69" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form">
					<div className="input-field">
						<input id="mce-EMAIL" type="email" name="EMAIL" placeholder="Email Address" className="email validate" required />
						{/* <label for="email">Email</label> */}
						<div aria-hidden="true"
							style={{
									display: `none`
							}}>
							<input type="text" name="b_7f9dc28cc5061e5b17f436c03_39a76ccf69" tabIndex="-1" />
						</div>
					</div>
					<button id="mc-embedded-subscribe" className="btn" type="submit" name="subscribe">Subscribe</button>
				</form>
			</div>
		</div>
	</div>
)

export default Subscribe;